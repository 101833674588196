var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          attrs: {
            model: _vm.formData,
            "label-position": "right",
            "label-width": "80px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "应用ID:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.appId,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "appId", _vm._n($$v))
                  },
                  expression: "formData.appId"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "淘客ID:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.userId,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "userId", _vm._n($$v))
                  },
                  expression: "formData.userId"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "外部用户ID:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.outUid,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "outUid", $$v)
                  },
                  expression: "formData.outUid"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "父级id:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.parentId,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "parentId", _vm._n($$v))
                  },
                  expression: "formData.parentId"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "关系链:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.path,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "path", $$v)
                  },
                  expression: "formData.path"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "手机号:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.phone,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "phone", $$v)
                  },
                  expression: "formData.phone"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "国家区号:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.areaCode,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "areaCode", $$v)
                  },
                  expression: "formData.areaCode"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "密码:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.password,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "password", $$v)
                  },
                  expression: "formData.password"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "昵称:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.name,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "name", $$v)
                  },
                  expression: "formData.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "真实姓名:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.realName,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "realName", $$v)
                  },
                  expression: "formData.realName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "头像:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.avatar,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "avatar", $$v)
                  },
                  expression: "formData.avatar"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "性别：0-未知 1-男 2-女:" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949",
                  "active-text": "是",
                  "inactive-text": "否",
                  clearable: ""
                },
                model: {
                  value: _vm.formData.sex,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "sex", $$v)
                  },
                  expression: "formData.sex"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "角色：1-普通用户:" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949",
                  "active-text": "是",
                  "inactive-text": "否",
                  clearable: ""
                },
                model: {
                  value: _vm.formData.type,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "type", $$v)
                  },
                  expression: "formData.type"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "角色充值到期时间:" } },
            [
              _c("el-date-picker", {
                attrs: { type: "date", placeholder: "选择日期", clearable: "" },
                model: {
                  value: _vm.formData.expireTime,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "expireTime", $$v)
                  },
                  expression: "formData.expireTime"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "角色：0-非会员，1-一级会员:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.plusLevel,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "plusLevel", _vm._n($$v))
                  },
                  expression: "formData.plusLevel"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "会员充值到期时间:" } },
            [
              _c("el-date-picker", {
                attrs: { type: "date", placeholder: "选择日期", clearable: "" },
                model: {
                  value: _vm.formData.plusExpire,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "plusExpire", $$v)
                  },
                  expression: "formData.plusExpire"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "支付宝账号:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.aliAccount,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "aliAccount", $$v)
                  },
                  expression: "formData.aliAccount"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "微信号:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.wxAccount,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "wxAccount", $$v)
                  },
                  expression: "formData.wxAccount"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "注册时间:" } },
            [
              _c("el-date-picker", {
                attrs: { type: "date", placeholder: "选择日期", clearable: "" },
                model: {
                  value: _vm.formData.registerTime,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "registerTime", $$v)
                  },
                  expression: "formData.registerTime"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "最后一次登录时间:" } },
            [
              _c("el-date-picker", {
                attrs: { type: "date", placeholder: "选择日期", clearable: "" },
                model: {
                  value: _vm.formData.lastLoginTime,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "lastLoginTime", $$v)
                  },
                  expression: "formData.lastLoginTime"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "推荐码:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.recommendCode,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "recommendCode", $$v)
                  },
                  expression: "formData.recommendCode"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "团队人数:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.teamNum,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "teamNum", _vm._n($$v))
                  },
                  expression: "formData.teamNum"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "淘宝授权:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.tbToken,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "tbToken", $$v)
                  },
                  expression: "formData.tbToken"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "淘宝:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.taobaoOpenId,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "taobaoOpenId", $$v)
                  },
                  expression: "formData.taobaoOpenId"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "邀请码:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.inviteCode,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "inviteCode", $$v)
                  },
                  expression: "formData.inviteCode"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "渠道ID:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.relationId,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "relationId", $$v)
                  },
                  expression: "formData.relationId"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "会员ID:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.specialId,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "specialId", $$v)
                  },
                  expression: "formData.specialId"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "饿了么渠道ID:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.elmRelationId,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "elmRelationId", $$v)
                  },
                  expression: "formData.elmRelationId"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "渠道:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.channel,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "channel", _vm._n($$v))
                  },
                  expression: "formData.channel"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "storeTime字段:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.storeTime,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "storeTime", $$v)
                  },
                  expression: "formData.storeTime"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "scene字段:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.scene,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "scene", $$v)
                  },
                  expression: "formData.scene"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "checkTimes字段:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.checkTimes,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "checkTimes", _vm._n($$v))
                  },
                  expression: "formData.checkTimes"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "isBlacklist字段:" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949",
                  "active-text": "是",
                  "inactive-text": "否",
                  clearable: ""
                },
                model: {
                  value: _vm.formData.isBlacklist,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "isBlacklist", $$v)
                  },
                  expression: "formData.isBlacklist"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.back } },
                [_vm._v("返回")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }